<template>
  <div class="yeji-from-wrap">
    <el-form label-position="left" ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="125px"
      class="demo-ruleForm">
      <el-form-item label="方案名称：" prop="ruleName">
        <div class="ruleName">
          <el-input size="small" v-model="ruleForm.ruleName" placeholder="方案名称" />
        </div>
      </el-form-item>

      <el-form-item label="提成计算类型：" prop="calKind">
        <el-radio-group @change="handleCountKindChange" fill="#FF5733" size="small" v-model="ruleForm.calKind">
          <el-radio-button label="1">每单计算
          </el-radio-button>
          <el-radio-button label="2">阶段式计算
          </el-radio-button>
          <el-radio-button label="3">阶梯式计算
          </el-radio-button>
        </el-radio-group>
      </el-form-item>


      <el-form-item label="关联角色：" prop="relateiDs">
        <div class="opt-shop">
          <el-button @click="handleOptRoleClick" size="small" plain>{{ ruleKind == 1 || ruleKind == 2 ? '选择门店' : '选择角色'
          }}
          </el-button>
          <div class="opt-shopname">
            已选: <template :key="roleitem" v-for="roleitem in currentOptRoleName">
              <el-tag size="small">{{ roleitem }}</el-tag>
            </template>
          </div>
        </div>
        <!-- <el-checkbox-group v-model="ruleForm.relateiDs">
          <template v-if="ruleKind == 1 || ruleKind == 2">
            <template :key="item" v-for="item in shopList">
              <el-checkbox :label="item.shopId">{{ item.shopName }}</el-checkbox>
            </template>
          </template>
          <template v-else>
            <template :key="item" v-for="item in roleList">
              <el-checkbox :label="item.lvId">{{ item.levelName }}</el-checkbox>
            </template>
          </template>
        </el-checkbox-group> -->
      </el-form-item>

      <el-form-item class="xiaohao-yeji-form" label="消耗业绩：" prop="chuzhikabenjin">
        <div class="xiaohao-yeji yj-value">
          <div v-if="ruleKind == 1 || ruleKind == 3" class="opt-checkbox">
            <div class="opt-title">单次消费</div>
            <el-form-item label-width="0" prop="danci">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.danci">
                <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button>
                <el-radio-button label="3">不计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="opt-checkbox">
            <div class="opt-title">储值卡-本金</div>
            <el-form-item label-width="0" prop="chuzhikabenjin">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.chuzhikabenjin">
                <!-- <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button> -->
                <el-radio-button label="3">不计算
                </el-radio-button>
                 <el-radio-button label="4">计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <div class="opt-title opt-title-btottom">储值卡-赠金</div>
            <el-form-item label-width="0" prop="chuzhikazengsong">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.chuzhikazengsong">
                <!-- <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button> -->
                <el-radio-button label="3">不计算
                </el-radio-button>
                 <el-radio-button label="4">计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

          </div>

          <div class="opt-checkbox">
            <div class="opt-title">账户余额-本金</div>
            <el-form-item label-width="0" prop="yuebenjin">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.yuebenjin">
                <!-- <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button> -->
                <el-radio-button label="3">不计算
                </el-radio-button>
                 <el-radio-button label="4">计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <div class="opt-title opt-title-btottom">账户余额-赠金</div>
            <el-form-item label-width="0" prop="yuezengsong">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.yuezengsong">
                <!-- <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button> -->
                <el-radio-button label="3">不计算
                </el-radio-button>
                <el-radio-button label="4">计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

          </div>

          <div class="opt-checkbox">
            <div class="opt-title">有限次卡</div>
            <el-form-item label-width="0" prop="youxianbenjin">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.youxianbenjin">
                <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button>
                <el-radio-button label="3">不计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <div class="opt-title opt-title-btottom">有限次卡-赠送</div>
            <el-form-item label-width="0" prop="youxianzengsong">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.youxianzengsong">
                <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button>
                <el-radio-button label="3">不计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

          </div>

          <div class="opt-checkbox">
            <div class="opt-title">不限次卡</div>
            <el-form-item label-width="0" prop="buxianbenjin">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.buxianbenjin">
                <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button>
                <el-radio-button label="3">不计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <div class="opt-title opt-title-btottom">不限次卡-赠送</div>
            <el-form-item label-width="0" prop="buxianzengsong">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.buxianzengsong">
                <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button>
                <el-radio-button label="3">不计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>

          <div class="opt-checkbox">
            <div class="opt-title">定制卡</div>
            <el-form-item label-width="0" prop="dingzhiben">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.dingzhiben">
                <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button>
                <el-radio-button label="3">不计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

            <div class="opt-title opt-title-btottom">定制卡-赠送</div>
            <el-form-item label-width="0" prop="dingzhizengsong">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.dingzhizengsong">
                <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button>
                <el-radio-button label="3">不计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

          </div>

          <div class="opt-checkbox">
            <div class="opt-title">产品</div>
            <el-form-item label-width="0" prop="shangpin">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.shangpin">
                <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button>
                <el-radio-button label="3">不计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

          </div>

          <div class="opt-checkbox">
            <div class="opt-title">兑换券</div>
            <el-form-item label-width="0" prop="duihuan">
              <el-radio-group fill="#6D6D6D" size="small" v-model="ruleForm.duihuan">
                <el-radio-button label="1">原价
                </el-radio-button>
                <el-radio-button label="2">实收
                </el-radio-button>
                <el-radio-button label="3">不计算
                </el-radio-button>
              </el-radio-group>
            </el-form-item>

          </div>

        </div>
      </el-form-item>

      <el-form-item label="多人分配：" prop="sharetype">
        <div class="yj-value duoren-fenpei">
          <el-radio-group @change="handleSharetypeChange" v-model="ruleForm.sharetype">
            <el-radio :label="1">平均分配</el-radio>
            <el-radio @click.stop="handleShoudongSettingClick" :label="2">手动设置</el-radio>
          </el-radio-group>
          <duorenFenpeiDialog @changeYejiSave="changeYejiSave" @yejiFormChange="yejiFormChange" v-show="isShowPnum">
          </duorenFenpeiDialog>
          <div v-show="ruleForm.sharetype == 2" class="pnum-title">
            <div>人数</div>
            <div>第1人</div>
            <div>第2人</div>
            <div v-if="yejiForm.length >= 3">第3人</div>
            <div v-if="yejiForm.length >= 4">第4人</div>
            <div v-if="yejiForm.length >= 5">第5人</div>
            <!-- <div>操作</div> -->
          </div>
          <!-- <h1>{{yejiForm}}</h1> -->
          <div v-show="ruleForm.sharetype == 2" class="renshu-value">
            <div>{{ yejiForm.length }}</div>
            <div>{{ yejiForm[0].value }}</div>
            <div>{{ yejiForm[1].value }}</div>
            <div v-if="yejiForm.length >= 3">{{ yejiForm[2].value }}</div>
            <div v-if="yejiForm.length >= 4">{{ yejiForm[3].value }}</div>
            <div v-if="yejiForm.length >= 5">{{ yejiForm[4].value }}</div>
            <!-- <div></div> -->
          </div>
        </div>
      </el-form-item>

    </el-form>


    <div class="yeji-form">
      <div class="yj-label">
      </div>
      <div class="yj-value save-from">
        <el-button @click="handleNextClick(ruleFormRef)" size="large" color="#2A82E4" type="primary">保存方案</el-button>
        <div class="tips">下一步：配置提成规则</div>
      </div>
    </div>

    <distributeDialog :ruleId="$route.query.ruleId" :oldBindRole="ruleForm.relateiDs" :ruleKind="ruleKind"
      @changeOptShop="changeOptShop" @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible">
    </distributeDialog>
  </div>
</template>

<script lang="js">
import emitter from '@/utils/eventbus'
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { COMPANY_ID } from '@/common/constant'
import duorenFenpeiDialog from './cpns/duoren-fenpei-dialog/duoren-fenpei-dialog.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { deepClone } from '@/utils/clone'
import lodash from 'lodash'
import { addRule, updateRule } from '@/service/main/commission'
import { getRuleDetail } from '@/service/main/commission'
import { rules } from '../../config'
import distributeDialog from '../../base-ui/distribute-dialog/distribute-dialog.vue'
export default defineComponent({
  props: {

  },
  components: {
    duorenFenpeiDialog,
    distributeDialog
  },
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const ruleKind = computed(() => route.query.ruleKind)
    const ruleForm = reactive({
      id: route.query.ruleId,
      ruleName: "",//规则名称
      companyId: "10000",//公司id
      calKind: '1',//计算方式1每笔计算2阶段计算3阶梯计算
      ruleKind: route.query.ruleKind,//规则分类1门店消耗2门店消费3角色消耗4角色消费
      danci: "",//单次消费:1原价2实收3不计算
      chuzhikabenjin: "",//储值卡本金
      chuzhikazengsong: "",//储值卡赠金
      yuebenjin: "",//余额本金
      yuezengsong: "",//余额赠送
      youxianbenjin: "",//有限次卡本金
      youxianzengsong: "",//有限次卡赠送
      buxianbenjin: "",//不限次卡本金
      buxianzengsong: "",//不限次卡赠送
      dingzhiben: "",//定制卡本金
      dingzhizengsong: "",//定制卡赠送
      shangpin: "",//商品
      duihuan: "",//兑换券
      sharetype: "",//分配规则1平均分配personXXX参数不传值，2手动分配，根据人数传值
      persontwo: "",//
      personthree: "",
      personfour: "",
      personfive: "",
      relateiDs: []//关联门店或角色id
    })



    const relateiDs = ref([])

    const isShowPnum = ref(false)
    const roleList = computed(() => store.state.commission.roleList)

    const handleNextClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          let obj = deepClone(ruleForm)
          // console.log(obj.relateiDs)
          if (Array.isArray(obj.relateiDs)) {
            let rolelist = deepClone(obj.relateiDs)
            let roleids = []
            rolelist.forEach(item => {
              if(item.relateId){
                roleids.push(item.relateId)
              } else{
                roleids.push(item)
              }

            })
            obj.relateiDs = roleids.join()
          }

          if (obj.sharetype == 2) {
            let ids = []
            yejiForm.value.forEach(item => {
              ids.push(item.value)
            })

            if (yejiForm.value.length == 2) {
              obj.persontwo = ids.join()
            } else if (yejiForm.value.length == 3) {
              obj.personthree = ids.join()
            } else if (yejiForm.value.length == 4) {
              obj.personfour = ids.join()
            } else if (yejiForm.value.length == 5) {
              obj.personfive = ids.join()
            }
          }

          const res = await updateRule(obj)
          if (res.code == 0) {
            emit('changeNextClick', {
              calKind: ruleForm.calKind,
            })
          }
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })




      // emit('changeNextClick', {
      //   calKind: ruleForm.calKind,
      //   rid: ''
      // })

    }

    const handleCountKindChange = (value) => {
      emit('countKindChange', value)
      // console.log(value);
    }

    const handleSharetypeChange = (value) => {
      if (value == 2) {
        isShowPnum.value = true
      } else {
        isShowPnum.value = false
      }
      // console.log(value);
    }

    const radio2 = ref('')
    const handleyejiClick = () => {
      isShowPnum.value = false

    }

    const handleShoudongSettingClick = () => {
      // console.log(123);
      isShowPnum.value = true
    }

    const yejiForm = ref([
      { value: '' },
      { value: '' },

    ])

    const yejiFormChange = (value) => {
      yejiForm.value = value
    }

    const changeYejiSave = () => {
      isShowPnum.value = false
    }

    const shopList = computed(() => store.state.reserve.shopList)

    const ruleDetail = ref(null)
    const initPage = async () => {
      store.dispatch('reserve/getShopListAction', COMPANY_ID)
      store.dispatch('commission/getRoleListAction')
      const res = await getRuleDetail(COMPANY_ID, route.query.ruleId)
      ruleDetail.value = res.data

    }

    initPage()

    watch(ruleDetail, (n) => {

      if (n != null) {
        // console.log(n);
        let detail = n.detail
        ruleForm.ruleName = detail.ruleName
        ruleForm.danci = detail.danci
        ruleForm.chuzhikabenjin = detail.chuzhikabenjin
        ruleForm.chuzhikazengsong = detail.chuzhikazengsong
        ruleForm.yuebenjin = detail.yuebenjin
        ruleForm.yuezengsong = detail.yuezengsong
        ruleForm.youxianbenjin = detail.youxianbenjin
        ruleForm.youxianzengsong = detail.youxianzengsong
        ruleForm.buxianbenjin = detail.buxianbenjin
        ruleForm.buxianzengsong = detail.buxianzengsong
        ruleForm.dingzhiben = detail.dingzhiben
        ruleForm.dingzhizengsong = detail.dingzhizengsong
        ruleForm.shangpin = detail.shangpin
        ruleForm.duihuan = detail.duihuan
        ruleForm.calKind = detail.calKind
        ruleForm.sharetype = detail.sharetype
        n.bindItems.forEach(item => {
          ruleForm.relateiDs.push(item.relateId)
        })

        if (detail.sharetype == 2) {
          if (detail.persontwo != null && detail.persontwo.length > 0) {
            yejiForm.value = [
              {
                value: ''
              },
              {
                value: ''
              }
            ]
            detail.persontwo.split(',').forEach((item, index) => {
              yejiForm.value[index].value = item
            })
          }

          if (detail.personthree != null && detail.personthree.length > 0) {
            yejiForm.value = [
              {
                value: ''
              },
              {
                value: ''
              },
              {
                value: ''
              }
            ]
            detail.personthree.split(',').forEach((item, index) => {
              yejiForm.value[index].value = item
            })
          }

          if (detail.personfour != null && detail.personfour.length > 0) {
            yejiForm.value = [
              {
                value: ''
              },
              {
                value: ''
              },
              {
                value: ''
              }
              ,
              {
                value: ''
              }
            ]
            detail.personfour.split(',').forEach((item, index) => {
              yejiForm.value[index].value = item
            })
          }

          if (detail.personfive != null && detail.personfive.length > 0) {
            yejiForm.value = [
              {
                value: ''
              },
              {
                value: ''
              },
              {
                value: ''
              }
              ,
              {
                value: ''
              }
              ,
              {
                value: ''
              }
            ]
            detail.personfive.split(',').forEach((item, index) => {
              yejiForm.value[index].value = item
            })
          }
        }


      }
    }, {
      deep: true,
      immediate: true
    })


    const ruleFormRef = ref()
    const currentOptRoleName = ref('')
    const changeOptShop = (obj) => {
      // console.log('123')
      currentOptRoleName.value = obj.optNameArr
      ruleForm.relateiDs = obj.ids
    }

    const dialogVisible = ref(false)
    const changeDialogVisible = (flag) => {
      dialogVisible.value = flag
    }

    const handleOptRoleClick = () => {
      dialogVisible.value = true
    }



    return {
      dialogVisible,
      changeDialogVisible,
      handleOptRoleClick,
      currentOptRoleName,
      changeOptShop,
      rules,
      ruleFormRef,
      ruleDetail,
      shopList,
      ruleKind,
      changeYejiSave,
      yejiForm,
      yejiFormChange,
      handleShoudongSettingClick,
      handleyejiClick,
      radio2,
      ruleForm,
      handleNextClick,
      handleCountKindChange,
      COMPANY_ID,
      isShowPnum,
      handleSharetypeChange,
      roleList,
      relateiDs
    }

  }
})
</script>

<style scoped lang="less">
.yeji-from-wrap {
  min-height: 900px;

  .opt-shop {
    display: flex;

    .opt-shopname {
      margin-left: 12px;
      font-size: 12px;

      &:deep(.el-tag) {
        margin-left: 10px;
      }
    }
  }

  &:deep(.xiaohao-yeji-form) {
    .el-form-item__content {
      line-height: normal !important;
    }
  }

  .duoren-fenpei {
    padding: 0 10px;

    .renshu-value {
      display: flex;
      width: 455px;
      padding: 0 10px;

      &>div {
        flex: 1;
        line-height: 36px;
        font-size: 12px;
        color: rgba(80, 80, 80, 1);

      }
    }

    .pnum-title {
      display: flex;
      width: 455px;
      padding: 0 10px;
      background-color: rgba(231, 231, 231, 1);

      &>div {
        flex: 1;
        line-height: 36px;
        font-size: 12px;
        color: rgba(80, 80, 80, 1);

      }
    }
  }

  .xiaohao-yeji {
    display: flex;
    flex-wrap: wrap;

    .opt-checkbox {
      border-radius: 5px;
      font-size: 14px;
      width: 240px;
      padding: 16px 14px;
      min-height: 146px;
      border: 1px solid rgb(204, 204, 204, 1);
      margin-bottom: 12px;
      margin-right: 12px;


      .opt-title {
        margin-bottom: 11px;

      }

      .opt-title-btottom {
        margin: 16px 0;
      }
    }
  }

  .ruleName {
    &:deep(.el-input) {
      max-width: 260px;
    }
  }

  &:deep(.el-radio-button__original-radio:checked+.el-radio-button__inner) {
    background-color: rgb(109, 109, 109) !important;
    border-color: rgb(109, 109, 109) !important;
    box-shadow: none !important;
  }

  .fangan-name {
    &:deep(.el-input) {
      max-width: 262px;
    }
  }

  .yeji-form-center {
    align-items: center;
  }



  .yeji-form {
    display: flex;
    margin-bottom: 27px;
    font-size: 14px;

    .yj-label {
      width: 100px;
      font-weight: bold;
      color: rgba(80, 80, 80, 1);

    }

    .duoren-fenpei {
      padding: 0 10px;

      .renshu-value {
        display: flex;
        width: 455px;
        padding: 0 10px;

        &>div {
          flex: 1;
          line-height: 36px;
          font-size: 12px;
          color: rgba(80, 80, 80, 1);

        }
      }

      .pnum-title {
        display: flex;
        width: 455px;
        padding: 0 10px;
        background-color: rgba(231, 231, 231, 1);

        &>div {
          flex: 1;
          line-height: 36px;
          font-size: 12px;
          color: rgba(80, 80, 80, 1);

        }
      }
    }

    .yj-value {
      flex: 1;
      position: relative;

      .dr-fenpei {
        position: absolute;
      }
    }

    .save-from {
      display: flex;
      align-items: center;
      margin-top: 64px;

      &:deep(.el-button) {
        width: 213px;
        height: 48px;
      }

      .tips {
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        margin-left: 19px;
      }
    }

    .xiaohao-yeji {
      display: flex;
      flex-wrap: wrap;

      .opt-checkbox {
        border-radius: 5px;
        font-size: 14px;
        width: 240px;
        padding: 14px;
        min-height: 146px;
        border: 1px solid rgb(204, 204, 204, 1);
        margin-bottom: 12px;
        margin-right: 12px;

        .opt-title {
          margin-bottom: 11px;

        }

        .opt-title-btottom {
          margin-top: 11px;
        }
      }
    }
  }
}
</style>




